import { MOUNT_AFTER_MAP } from "./mountAfterMap";
import { GETScript } from "./utils/GET";

export const DEFAULT_PUBLISHER_PROPERTIES = {
	name: "DEFAULT_NAME",
	getMountAfter: null,
	hideInitially: false,

	preventBodyScrollLock: false,

	inlineStyles: {},
	fullscreenStyles: {},
	modalOverlayStyles: {},
	modalStyles: {},

	inlineCallback: false,
	fullscreenCallback: false,
	delayLoadDuration: 0,
};

const SPECIAL_PUBLISHERS_PROPERTIES_BASE = {
	"5a34d17c-2cc0-4aa7-9b14-73ff39bccbd7": {
		name: "Edith's mansion",
	},
	"6fa06f57-8501-4215-ad20-fa77c4dd0ea7": {
		name: "Bloomscape",
		modalOverlayStyles: {
			zIndex: 1099,
		},
		modalStyles: {
			zIndex: 1100,
		},
	},

	"dd0ab464-3865-433e-b8b6-112f429d1e2c": {
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_1,
		name: "Bokksu Market",
	},

	"d3f8d5cf-c8c6-4000-aedb-14d47ca2df89": {
		name: "Potency 710",
		modalStyles: {
			zIndex: 10001,
		},
	},
	"ae430b30-5e9b-4e93-bfeb-810d509958a3": {
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_LAST,
		name: "Tippsy Sake",
	},

	"e398d6c2-6625-473c-884e-3a4bd73d8088": {
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
		name: "Jot",
	},

	"6f376136-2a85-4adc-81bd-7be3793d2654": {
		getMountAfter: MOUNT_AFTER_MAP.SECTION_1,
		name: "Noli Yoga",
	},

	"4670573b-5371-4153-8fbb-0b735ff4cd4e": {
		getMountAfter: MOUNT_AFTER_MAP.SECTION_1,
		name: "MASKC",
	},

	"768e73f4-b028-4a35-85c3-a10b36e98e27": {
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
		name: "Little Words Project",
	},

	"2bd3d738-ac1c-4a20-933c-6b9d48aa6cac": {
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
		name: "AAVRANI",
	},

	"e3697d0d-a2af-4187-a2f2-60f21472cf33": {
		name: "Dr. Squatch",
		getMountAfter: MOUNT_AFTER_MAP.TARGETED_CUSTOM_ANCHOR(
			MOUNT_AFTER_MAP.CONTENT_BOX_0
		),
	},

	"a18bf69a-1bc6-4f70-b4d3-d419ae2b8b3d": {
		name: "Helix Sleep",
		inlineStyles: {
			zIndex: 1,
		},
	},

	"290eab97-fb38-4c37-8292-9d876d7bf1ee": {
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_1,
		name: "KraveBeauty",
	},

	"d9c9247a-6d34-4e7b-a081-3a27a67ec973": {
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
		name: "Liquid I.V.",
	},

	"df68ecef-a7f3-49d2-a6f9-b9c285ae205a": {
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
		name: "Hello Cake",
	},
	"9c7804be-e3dd-47e1-8da3-bd9e46121e0c": {
		name: "Nanit",
		fullscreenStyles: {
			zIndex: 10,
		},
		inlineStyles: {
			zIndex: 1,
		},
	},
	"22407dd0-01d2-4526-bdb3-d7514bdc309c": {
		name: "Dame",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},
	"c16a8789-11de-422c-a28b-032c54880eeb": {
		name: "Bad Birdie",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},
	"3c30cfa6-459b-4903-a9ea-e7a9bd9eec76": {
		name: "Cali's Books",
		fullscreenStyles: {
			zIndex: 10,
		},
		inlineStyles: {
			zIndex: 1,
		},
	},
	"32811b18-4a9f-4003-9899-8eb2e2c0c0a1": {
		name: "Marlowe's Roastery",
		// hideInitially: true,
		// fullscreenStyles: {
		// 	zIndex: 10,
		// },
		// inlineStyles: {
		// 	zIndex: 5,
		// },
		// disableModalMode: true,
		// hideInitially: true,
		// fullscreenCallback: () => console.log("FULL"),
		// inlineCallback: () => console.log("INLINE"),
		modalOverlayStyles: {
			zIndex: 1099,
		},
		modalStyles: {
			zIndex: 1100,
		},
		getMountAfter: () => document.querySelectorAll(".content-box")[0],
	},

	"e455eda6-81e4-4bbe-a78f-224b0d9e8549": {
		delayLoadDuration: 3000,
	},

	"8f73f52a-5fc9-4357-96c0-4732aa1a4502": {
		name: "Zitsticka",
		inlineStyles: {
			zIndex: 1,
		},
	},

	"9124cee3-60d0-4b95-9fd7-dc9eb1f35bf9": {
		getMountAfter: () => document.querySelectorAll(".content-box")[0],
	},

	"cc069815-8db6-45d8-b495-396c1107066d": {
		name: "Huron",
		getMountAfter: MOUNT_AFTER_MAP.THANK_YOU_ADDITIONAL_CONTENT,
	},
	"8b3de2ad-b60d-40a3-beae-51ba6521a7b8": {
		name: "Love + Chew Brands",
		getMountAfter: MOUNT_AFTER_MAP.THANK_YOU_ADDITIONAL_CONTENT,
	},
	"ef7065d8-8bda-4212-b8b4-aaf28635853e": {
		// TEST
		name: "Hydrant",
		getMountAfter: () => {
			const point3 = MOUNT_AFTER_MAP.CONTENT_BOX_3();
			return point3 ? point3 : MOUNT_AFTER_MAP.CONTENT_BOX_2();
		},
	},
	"de5b754e-eebe-4503-8725-e361882e3bdf": {
		name: "Fresh Clean Threads",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},
	"76dc3e57-070d-4bdf-a262-c14f66be9c27": {
		name: "Ursa Major",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},
	"5ad00947-d00b-4215-a5c1-e22b1d713db4": {
		name: "Lily Jade",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},
	"6972e5be-475b-447b-a43e-12d90a61bc1d": {
		name: "Brightland",
		getMountAfter: MOUNT_AFTER_MAP.VALID_CONTENT_BOX_AFTER_N(2),
	},
	"8482a224-cf75-41e8-9b92-91f885ad7222": {
		name: "Aura Bora",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_2,
	},

	"52faec35-d69d-4eba-8fd5-a74dad2ce278": {
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},

	"c15cb189-6d92-4c52-b80a-450a48477211": {
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_1,
	},

	"9201a3e8-bac7-455c-910a-4ce3acc5dfd2": {
		name: "Misen",
		getMountAfter: MOUNT_AFTER_MAP.VALID_CONTENT_BOX_AFTER_N(0),
	},

	"e9fbaffc-dff5-4aca-b06b-bce8f6acde65": {
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},

	"f8ad9bda-fdbe-4adc-82c1-a4acb719fa89": {
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},

	"f246413b-4fb8-4144-b618-7007dded23fd": {
		name: "Youth to the People",
		getMountAfter: MOUNT_AFTER_MAP.VALID_CONTENT_BOX_AFTER_N(0),
	},

	"02d0ad3e-1255-4c18-bea9-1392dacaae1a": {
		getMountAfter: () => document.querySelector("#disco-mount-after"),
	},

	"a7d6feac-a45e-11ea-bb37-0242ac130002": {
		// TEST => Done
		name: "Haus",
		getMountAfter: () => {
			const point1 = MOUNT_AFTER_MAP.CONTENT_BOX_2();
			return point1 ? point1 : MOUNT_AFTER_MAP.CONTENT_BOX_1();
		},
	},
	"c13a077b-7390-49ac-95e8-306db9d151ad": {
		// TODO
		name: "",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_2,
	},
	"0509492d-ba6c-4fac-be04-b3b6f7179bd9": {
		name: "Rind Snacks",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_2,
	},
	"778d6556-7469-4e61-a32d-b64c3ef2f7d1": {
		name: "Uqora",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_2,
	},
	"f47badeb-7e16-4f83-ae33-d1a7d2f136c1": {
		// TODO
		name: "",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_2,
	},
	"7ada07c0-243a-40f5-b27c-186bdb334bc7": {
		name: "BlendJet",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},
	"2226f2c6-841e-4343-97c5-613fa5e4658f": {
		name: "Caraway",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
		inlineStyles: {
			order: 0,
		},
	},
	"d2557cb4-d1b2-47c5-99b9-74e62b8c8ea5": {
		name: "JuneShine",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_2,
	},
	"06612039-93a7-41a1-97a1-b8d52501d97a": {
		// TEST
		name: "Bokksu",
		getMountAfter: () => {
			const el = MOUNT_AFTER_MAP.CONTENT_BOX_2;
			return el ? el : document.querySelector("#enquire-branding-tag");
		},
	},
	"dfb12cb0-bc69-4088-a41b-f449bbd35653": {
		name: "Sunwink",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_2,
	},
	"5788456e-50fc-4688-a5f0-b89d257570d2": {
		name: "Stojo",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_2,
	},
	"4c676cfb-a16f-438c-befb-47298b612a28": {
		name: "Stryx",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_2,
	},
	"f81889a9-63f4-4b5a-800a-6e7141a66211": {
		// TODO
		name: "",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_2,
	},
	"ae5535d8-c1a7-42e9-b705-700c62df1137": {
		name: "Bean & Bean",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_2,
	},
	"8bd84df3-5b2b-4267-abe0-f8cf9bd73058": {
		name: "Lambs",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_2,
	},
	"4be05480-7c57-4d03-a823-18a818069863": {
		name: "Orgain",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_2,
	},
	"7e23d1af-acff-47f2-90b9-7edbe21bb5e9": {
		// TEST => Churn
		name: "Coterie Staging",
		getMountAfter: () => {
			const el = MOUNT_AFTER_MAP.CONTENT_BOX_2();
			return el ? el : MOUNT_AFTER_MAP.CONTENT_BOX_1();
		},
	},
	"00236a03-8df5-4ae0-882d-07f5e890fbdc": {
		// TEST
		name: "Taza Ayurveda",
		getMountAfter: () => document.querySelector("#confirmed-page"),
	},
	"64d066ec-41a8-4abe-a086-659deb1683f7": {
		name: "Olipop",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_1,
	},
	"1bf6bb7d-ffdb-4e9c-b45f-8e20c1ea966b": {
		name: "Bace Health",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_1,
	},
	"fe5ba4bd-6626-4070-8cb8-553381144cbe": {
		name: "Blume",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_1,
	},
	"ab1886d2-ab54-44e1-8a42-7204f26a8f32": {
		name: "Open Spaces",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_1,
	},
	"c25545c6-39fa-462f-aa60-0671d960e83a": {
		// TEST => Done
		name: "Som Sleep",
		getMountAfter: MOUNT_AFTER_MAP.VALID_CONTENT_BOX_AFTER_N(1),
		preventBodyScrollLock: true,
	},
	"80b80b6f-385c-4197-9114-ac7125063854": {
		name: "Coterie",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"c0c7b2f6-71ca-456b-a439-11dd753366c9": {
		name: "Aplos",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_1,
	},
	"ec2f195f-cd8f-49f8-8b3c-bed1850d6d8e": {
		name: "Dreamland Baby",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_1,
	},
	"1173ec9d-919e-4ef8-8d64-5f8b365dfe5d": {
		name: "Girlfriend Collective",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"bd5d05c5-dbe4-4e05-84d2-ab27817f870c": {
		// TEST
		name: "Myro",
		getMountAfter: () => document.querySelector(".section--thank-you"),
	},
	"d47e6c71-1051-41e9-a627-636839e3e7b8": {
		name: "Pela Case",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},
	"cec0adc4-d6c1-4268-b2ec-943e9f01e8f5": {
		name: "Schoolyard Snacks",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},
	"2723d097-6112-4a73-a47e-bae1e492cd7e": {
		name: "Sapiens Beverage",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},
	"15277c03-e3fd-4129-a6d5-459c14152b30": {
		name: "Wren + Glory",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},
	"80e19b96-eb6f-45d6-8216-97ff16e3230d": {
		// TODO
		name: "",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},
	"0a804876-958c-4c90-9974-8328cc7b4b69": {
		name: "Nguyen coffee Supply",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},
	"58400fe6-bdde-4fc7-b2b7-53a70658ef62": {
		name: "Outer Aisle",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},
	"08261d41-41f1-4f33-ae81-7b6645fba07b": {
		name: "Offcourt",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},
	"2b1de073-afdc-421e-8e7e-adec8812b6f4": {
		name: "Moku Foods",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},
	"f708bee5-7f28-4768-9eb4-e4aa23e30416": {
		name: "Twice Toothpaste",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},
	"65a68eac-9a7c-4d6e-b26d-1d7ffedc7247": {
		name: "Zesti",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"d889bac1-df4f-4429-bbd3-6aece9cb0265": {
		name: "Porter Road",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},
	"d8c3642f-1cfd-46b8-8ba0-e0e3438915d5": {
		name: "Flock Foods",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},
	"1e2b4d51-1d89-4500-b4e4-56ea45050ed2": {
		name: "Hilma",
		getMountAfter: MOUNT_AFTER_MAP.VALID_CONTENT_BOX_AFTER_N(0),
	},
	"6c8736af-681a-499e-8ce6-400fe424887c": {
		name: "Faherty Brand",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},
	"3e1ba888-af6f-499e-95a8-a895efa5cea9": {
		name: "Q for Quinn",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},
	"76350267-a66e-4016-988e-c4b0aed0c201": {
		name: "Omsom",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},
	"27d7d886-a861-44c0-a679-42577b3631fa": {
		// TEST => Done
		name: "Andie",
		getMountAfter: MOUNT_AFTER_MAP.TARGETED_CUSTOM_ANCHOR(
			MOUNT_AFTER_MAP.CONTENT_BOX_0
		),
		fullscreenStyles: {
			zIndex: 10,
		},
		inlineStyles: {
			zIndex: 5,
		},
	},

	"2b33b56e-b161-43bd-8a2f-bf17a379d933": {
		name: "RAEN",
		inlineStyles: {
			zIndex: 1,
		},
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_LAST,
	},

	"b7dea133-983b-4e7b-8fac-b18d88340882": {
		name: "Once Upon a Farm",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},
	"b49851b2-9c52-40b4-ae11-49d3b4f88e63": {
		name: "Mack Weldon",
		getMountAfter: MOUNT_AFTER_MAP.VALID_CONTENT_BOX_AFTER_N(0),
	},
	"f737ac6e-a41b-48f0-99e2-a1418daa6b3b": {
		name: "Lumineux",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},
	"6a31c250-7bf2-4b91-95ba-e6feff421977": {
		name: "Jupiter",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},
	"d6fb6a97-3945-45b0-ac6f-443b1a0707da": {
		name: "Enso Rings",
		getMountAfter: MOUNT_AFTER_MAP.VALID_CONTENT_BOX_AFTER_N(0),
	},
	"8ad9da5d-344d-47be-aa89-153a035ea478": {
		name: "Foria",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"83596b74-469d-4198-b977-c34d1ce67836": {
		name: "Beekeeper's Naturals",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},
	"08c0635f-3956-41e3-905c-272263fb8d22": {
		// TEST
		name: "Yumi",
		getMountAfter: () => document.querySelector(".gpQcfc"),
		inlineStyles: {
			left: "30px",
		},
		inlineCallback: (iFrame) => {
			if (window.innerWidth < 770) {
				iFrame.style.width = "307px";
			} else {
				iFrame.style.width = "625px";
			}
		},
	},
	"403c9582-5815-4c4e-9104-abe394561069": {
		// @Rohan
		name: "",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},
	"52e9a990-e6b0-4dd8-a104-38df4e4511f0": {
		// TEST
		name: "TOVALA",

		delayLoadDuration: 2000,
		disableModalMode: true,
		hideInitially: true,

		getMountAfter: MOUNT_AFTER_MAP.COOP_MOUNT_AFTER,
	},
	"a2144121-87bd-4aa3-a662-9f3288e3e1f0": {
		name: "",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},
	"ef7a8166-16f5-4ef8-bd9e-8cd794433af7": {
		// TEST
		name: "AMASS",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
		fullscreenStyles: {
			zIndex: 10,
		},
		inlineStyles: {
			zIndex: 5,
		},
	},

	"110b69ec-d2a4-48f3-be33-efb0f9d2d485": {
		hideInitially: true,
	},

	"66076692-5a59-4e1b-ab37-2cde3235d6bb": {
		// TEST
		name: "ADAY",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
		fullscreenStyles: {
			zIndex: 10,
		},
		inlineStyles: {
			zIndex: 5,
		},
	},
	"0d344a1a-fa4c-4836-82d8-7da0bb04a60d": {
		name: "",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},
	"14a2d993-46db-4efb-b20f-b6f34a579888": {
		name: "Vegamour",
		getMountAfter: MOUNT_AFTER_MAP.VALID_CONTENT_BOX_AFTER_N(0),
	},
	"0a2beaba-d55a-4df1-a280-416a3171ce3d": {
		name: "",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},
	"1eb18a78-ebda-4a6a-8437-d0f94fe319b6": {
		name: "",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},
	"9723b5fc-dc62-4479-acec-a44ea39ab36c": {
		name: "",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},
	"d5473819-f6eb-4248-a767-b2150adae137": {
		name: "",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},
	"231c53cb-8d44-4f79-bd2b-2464b44e3210": {
		name: "",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},
	"da9c1172-323c-490e-b9f7-2249703c1d00": {
		name: "",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},
	"afcd9016-5429-4f23-b587-bb6817a79acd": {
		name: "",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},
	"56c906b8-181a-43a8-8699-364f2b9cd0f2": {
		name: "",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},
	"1589ac9f-85d7-41f0-9bad-937da3ead90f": {
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_LAST,
	},
	"9bfc420c-a322-4c14-99e8-10c0c2d02651": {
		name: "NEIWAI",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
		inlineStyles: {
			zIndex: 1,
		},
	},
	"95b72451-52fe-4e4c-a9a8-7739276bdc2e": {
		name: "JOI",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},

	"79ad94e7-9446-4328-afda-f371d67265eb": {
		name: "Fable Home",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},

	"bdbe3109-a8fe-4a97-a309-99001bae6d10": {
		name: "True Botanicals",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},

	"8c7b83fe-733e-49c8-a9c2-2d5dc6e14652": {
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_LAST,
	},

	"ae691190-f4f8-4b11-becf-492a0c1f1ee5": {
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_LAST,
	},

	"a76f1394-daee-4e55-888b-33ba50ddf7fd": {
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_LAST,
	},

	"394d88c7-9e09-4fe8-b28e-f219c8a78f5f": {
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_LAST,
	},

	"2dd80ae5-d531-48d6-8d84-4f3cfe04f7eb": {
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_LAST,
	},

	"e928cd2e-7357-43f6-be20-26da9648d873": {
		name: "Bear Mattress",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},

	"2ffd756f-74e4-4d1f-9d39-1f4f4c229276": {
		name: "Felix Gray",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},

	"5365ed27-2f7c-4ee7-aa85-ad1a5a8c3a72": {
		getMountAfter: MOUNT_AFTER_MAP.THANK_YOU_CHECKOUT_TEXT,
	},

	"98494fbe-70b1-4ca4-aa77-d45d7c439288": {
		getMountAfter: MOUNT_AFTER_MAP.WOOCOMMERCE_ORDER_OVERVIEW,
	},

	"3c1ff7cb-3e62-445a-b6a7-992d77cad2a2": {
		getMountAfter: MOUNT_AFTER_MAP.SECTION_1,
	},

	"52e9a990-e6b0-4dd8-a104-38df4e4511f0": {
		getMountAfter: MOUNT_AFTER_MAP.COOP_MOUNT_AFTER,
	},
	"a8d9f04b-dd6a-4a0b-8fd1-670abd77358f": {
		getMountAfter: MOUNT_AFTER_MAP.COOP_MOUNT_AFTER,
	},
	"95a21c28-61c4-4d4f-9b4b-c64f556d362b": {
		getMountAfter: MOUNT_AFTER_MAP.COOP_MOUNT_AFTER,
	},
	"26106162-b062-4874-ae09-d6d563f13a70": {
		getMountAfter: MOUNT_AFTER_MAP.COOP_MOUNT_AFTER,
	},
	"0d0104f2-1584-4ec2-bcb4-2d4295ab611b": {
		getMountAfter: MOUNT_AFTER_MAP.COOP_MOUNT_AFTER,
	},

	"a59c4e08-bad8-4b80-a7dc-6cf9d0112264": {
		// TEST
		name: "Inspiro Tequila",
		leftShiftModalButton: true,
		inlineStyles: { zIndex: 1000 },
	},
	"cd57f149-fd10-4cce-80db-193febe6b141": {
		// TEST
		name: "Lumin",
		getMountAfter: MOUNT_AFTER_MAP.COOP_MOUNT_AFTER,
		hideInitially: true,
		delayLoadDuration: 5500,
		inlineStyles: {
			"max-width": "1148px",
			margin: "10px auto",
		},
	},
	"7d069a1c-6969-4a9e-a4f1-4bb754470143": {
		// TEST
		name: "Meridien Grooming",
		getMountAfter: MOUNT_AFTER_MAP.COOP_MOUNT_AFTER,
		hideInitially: true,
		delayLoadDuration: 3400,
	},
	"c70750bf-b224-4b0e-b0c6-63416801f5a3": {
		getMountAfter: MOUNT_AFTER_MAP.COOP_MOUNT_AFTER,
	},

	"4be29e9d-15cd-41d8-831b-bafbd59603d6": {
		// TEST
		name: "Moxie Lashes",
		getMountAfter: () => document.querySelectorAll(".section > div")[1],
		delayLoadDuration: 2000,
	},

	"a401a278-a45e-11ea-bb37-0242ac130002": {
		// TEST
		name: "Curie",
		fullscreenStyles: {
			zIndex: 10000,
		},
	},

	"1d59d4b5-df42-4ae7-82e0-b89056ed14df": {
		// TEST
		name: "Archer Roose Wines",
		fullscreenStyles: {
			zIndex: 1000,
		},
		inlineStyles: {
			zIndex: 1,
		},
	},

	"3b17bcd7-a495-4fc5-a0a3-264bd923e024": {
		// TEST => Done
		name: "Olive & June",
		preventBodyScrollLock: true,
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},

	"97a4de2b-9995-4e96-aae5-3f617885ae5b": {
		// TEST
		name: "Doe Lashes",
		preventBodyScrollLock: true,
	},
	"07151c98-464d-4337-ab9e-2bb10e96d675": {
		// TEST
		name: "Bravo Sierra",
		preventBodyScrollLock: true,
		inlineStyles: {
			zIndex: 500,
		},
		fullscreenStyles: {
			zIndex: 500,
		},
	},
	"fb6998c2-e8d0-470c-a6f2-576c42a92b3c": {
		name: "Paragon Fitwear",
		preventBodyScrollLock: true,
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},
	"ecbcf3f6-b1d4-4aba-a0b0-914942e13759": {
		name: "Eyebobs",
		preventBodyScrollLock: true,
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"77d0fcea-caec-4e28-91c0-f9b1741e6772": {
		name: "Sprout Living",
		preventBodyScrollLock: true,
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"aa1992c8-1054-454d-b326-01f5e005bfc2": {
		name: "Dirty Labs",
		preventBodyScrollLock: true,
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_LAST,
	},
	"0c3aaba9-cc76-4b72-9b2c-8c582ae3b590": {
		name: "TB12",
		preventBodyScrollLock: true,
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},
	"852075c5-7cc0-4419-a484-0ef4efc30128": {
		name: "Awe Inspired",
		preventBodyScrollLock: true,
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_LAST,
	},
	"79350844-ccd1-4c97-947a-5bf605ff6f04": {
		name: "My Medic",
		preventBodyScrollLock: true,
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
		zIndex: 1,
	},
	"fcba576a-be6e-4709-a130-9872f8465d87": {
		// TEST => Done
		name: "AVEC",
		preventBodyScrollLock: true,
	},
	"90244175-bfec-4a95-bfa6-99ea5b2ed067": {
		// TEST
		name: "Kopari",
		preventBodyScrollLock: true,
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"a26fac49-0a67-4100-9f90-aa9a20a6e2de": {
		// TEST
		name: "Original Grain",
		inlineStyles: {
			zIndex: 1,
		},
	},
	"0a611cd4-8b32-4274-b295-49d6d8d98bf6": {
		// TEST
		name: "Esker",
		inlineStyles: {
			zIndex: 1,
		},
	},
	"3fb6382a-6eb9-4474-8917-8c3fb6a68788": {
		// TEST
		name: "LIFEAID",
		getMountAfter: MOUNT_AFTER_MAP.COOP_MOUNT_AFTER,
		inlineStyles: {
			zIndex: 1,
		},
	},
	"73f0a1a2-20c0-417f-a7ae-404da4781800": {
		// TEST => No data
		name: "Quip",
		fullscreenStyles: {
			zIndex: 10,
		},
		inlineStyles: {
			zIndex: 5,
		},
	},
	"98056b39-4963-479b-9ea6-f726c7320641": {
		// TEST => No data
		name: "Quip Staging",
		fullscreenStyles: {
			zIndex: 10,
		},
		inlineStyles: {
			zIndex: 5,
		},
	},
	"9afb6a60-9f4d-4820-8a43-1c4381d45dad": {
		// TEST
		name: "Milk Bar",
		getMountAfter: MOUNT_AFTER_MAP.MAIN_CONTENT,
		fullscreenStyles: {
			zIndex: 10,
		},
		inlineStyles: {
			zIndex: 5,
		},
	},
	"5d9a0cc6-4edf-4c4b-99ea-afebb7b5b43a": {
		name: "Ekster",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_1,
	},
	"308dd5b8-0678-4840-a2cc-648ac7b9246b": {
		name: "Brook & York",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"02d305cd-a711-4fe5-8f06-1a08b72ab104": {
		name: "INH Hair",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"6365ae0d-0ee5-4078-bd9f-f1bd87e808c7": {
		name: "Something Navy",
		getMountAfter: MOUNT_AFTER_MAP.VALID_CONTENT_BOX_AFTER_N(3),
	},
	"64d011fc-5365-454a-b44a-fac41edefcee": {
		name: "Farmers Juice",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},
	"e3e7035b-1722-48d6-a419-e111ce9eab92": {
		name: "Cocokind",
		getMountAfter: MOUNT_AFTER_MAP.VALID_CONTENT_BOX_AFTER_N(0),
	},
	"cc22dd84-df2b-4914-8d6d-b9bfd7cdae56": {
		name: "Vosges Haut-Chocolat",
		fullscreenStyles: {
			zIndex: 35,
		},
		inlineStyles: {
			zIndex: 1,
		},
	},
	"b322d443-adad-4486-ac20-4127e5ccf755": {
		name: "Lunya",
		fullscreenStyles: {
			zIndex: 35,
		},
		inlineStyles: {
			zIndex: 1,
		},
	},
	"4875110b-13c0-47c9-b16a-9993510b170a": {
		name: "Belgian Boys",
		fullscreenStyles: {
			zIndex: 35,
		},
		inlineStyles: {
			zIndex: 1,
		},
	},
	"d7da3f05-8f7d-4b72-ad3c-b8a82ad64fe0": {
		name: "Deux par Deux",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},
	"1c142b99-f55f-46b6-9a5f-f966029651c4": {
		name: "Social CBD",
		getMountAfter: () => document.querySelector("#disco-mount-after"),
	},
	"bb7637f5-2c95-4a48-92c6-14569c686ea3": {
		name: "Branch",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"11631591-f491-4419-ac5f-d4740d697c9d": {
		name: "Newton Baby",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"da0a0e13-cf28-43df-946f-fedf46b04b07": {
		name: "Cleverman",
		getMountAfter: () => document.querySelector("#disco-mount-after"),
	},
	"dfb69b57-43d2-42db-9722-a002455a3901": {
		name: "Rumpl",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"f1593fa9-0e67-4838-9a72-8b951edc6943": {
		name: "TUSHY",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_LAST,
	},
	"dc127f6d-7a01-4a5b-8797-b4bb104dad06": {
		name: "The Bouqs Co.",
		getMountAfter: () => document.querySelector("#disco-mount-after"),
	},
	"febc4fa1-b976-4856-9df2-12f2db54eec9": {
		name: "Highline Wellness",
		getMountAfter: MOUNT_AFTER_MAP.VALID_CONTENT_BOX_AFTER_N(0),
	},
	"7fa092b9-21b5-4693-99e3-08587c627d2d": {
		name: "Kencko",
		getMountAfter: () => {
			let mountAfter = document.querySelector(
				".ContentBlock--CustomerInformation"
			);
			if (!mountAfter) {
				mountAfter = document.querySelector(".section");
			}
			return mountAfter;
		},
	},
	"9f03ac81-dbbb-4fb3-bb4a-9078400c9e3f": {
		name: "Thorlos",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_LAST,
	},
	"00449956-4407-44b2-948e-a8ae66fcaa58": {
		name: "The HoneyPot",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_LAST,
	},
	"99c209cd-46de-49e1-be96-92572bbffdab": {
		name: "minnow",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"632187b7-57e4-4c40-aa4a-56b546bf21c1": {
		name: "Blueland",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"22db79e9-f7e5-4761-83b2-64ab2c041a77": {
		name: "Miami Beach Bum",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_LAST,
	},
	"39f849cd-dc37-43bb-9097-11737e979346": {
		name: "Ancient Nutrition",
		getMountAfter: MOUNT_AFTER_MAP.TARGETED_CUSTOM_ANCHOR(
			MOUNT_AFTER_MAP.CONTENT_BOX_1
		),
	},
	"4e48bed3-f289-427a-af0d-9243b91d7328": {
		name: "Shinesty",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_LAST,
	},
	"fab95b0c-6856-417c-ae72-552c228efa7c": {
		name: "Lively",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"bb564b13-c043-4764-8771-e550b7ab42d0": {
		name: "ByHeart",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"cd2d48cc-f576-4f6d-bf19-0fb9ccf74782": {
		name: "OUAI",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"bc9f887b-689c-495c-ae7e-1acef4fcb2be": {
		name: "Trilastin",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"ec6ba4c7-5773-47c7-8785-9810361d5d00": {
		name: "Tiege Hanley",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_LAST,
	},
	"25b79f60-3adc-4923-9ad9-154c96a67133": {
		name: "Divi",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"d27f7f3e-dfbe-426c-b8e9-2e8e9f3ee909": {
		name: "Chamberlain Coffee",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_LAST,
	},
	"381ff2be-bc16-460a-9849-c8918ba716f2": {
		name: "Lenses",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"38e075f7-3310-44e5-9f8c-3a90caca0252": {
		name: "nood",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"28dedc5b-bedf-4cd9-89cf-5b4d315fdfd2": {
		name: "Senreve",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"62a41575-07cc-4bba-90e9-dc2c0e828ad4": {
		name: "Birthdate Candles",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_LAST,
	},
	"6c478bec-db81-4998-b4e2-89ea5cf5e7ad": {
		name: "Rose Forever",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"5cced413-9387-4130-8dac-c0e9dc4061a9": {
		name: "Cure",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_LAST,
	},
	"6e7e091d-c0bb-4992-918e-deabd3de5a02": {
		name: "Riot Society",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"b6f8dbb2-3e80-4126-8a68-fc2f8130b526": {
		name: "Dagne Dover",
		inlineStyles: {
			zIndex: 1,
		},
	},
	"de5b754e-eebe-4503-8725-e361882e3bdf": {
		name: "Fresh Clean Threads",
		inlineStyles: {
			zIndex: 1,
		},
	},
	"bd953eb5-9c5d-49b4-8b8a-e6380aa2fa38": {
		name: "Manukora",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"deefa975-a956-4fb4-b5b2-bc589b907735": {
		name: "Toad&Co",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_LAST,
	},
	"9a3f238c-4b8e-4fba-874a-8b19ae7166b2": {
		name: "cbdMD",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"3e5713ea-d9d5-4792-b7b1-642f3c118e45": {
		name: "GRIP6",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"be32c6f4-b4ed-4f2b-b369-beee7d0da00a": {
		name: "ARMRA Colostrum",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"bf46dc19-77c1-4280-a3e3-8a20a339224d": {
		name: "UNTUCKit Staging",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_LAST,
	},
	"b371bacc-0ec2-4a63-be61-5f189dd9b950": {
		name: "nood",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"e40e97b4-186a-4d3e-a544-0824100f11d5": {
		name: "UNTUCKit",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_LAST,
	},
	"ef364f56-b249-4f64-ba4a-aeb84f972078": {
		name: "Homesick Candles",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"194b5b84-b0c4-43fa-991d-d6c4ed905913": {
		name: "Willow Pumps",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"9d8ed467-9b44-4b78-993b-bba5386d1835": {
		name: "Snuggle Me Organic",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},
	"8cad539a-2f64-4008-9a7d-a3ac881cfb23": {
		name: "Dropps",
		inlineStyles: {
			zIndex: 1,
		},
	},
	"3499593b-a773-4f98-9f1c-b4386365d888": {
		name: "Viori Beauty",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"26dee291-34ce-406d-ae6e-76f025a5f09b": {
		name: "Jolie",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"c5354192-627d-4018-b77a-1878400ca782": {
		name: "Gravity Blankets",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"be6ab495-ebf0-4fd6-97c5-1462398f6e3b": {
		name: "Reel Paper",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"37a47ae5-4cc7-4c7a-8944-a35503be444e": {
		name: "Ora Organic",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"a657372b-2ce6-46c3-8ea5-4ce45fc93497": {
		name: "QALO",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"5dadfd73-1e18-4821-8783-2387ffe5dd9b": {
		name: "Love your Melon",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"484be1d7-6cc3-4a0a-a4c4-58e68272d8e1": {
		name: "Blessed Be Magick",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"63a7287e-5668-4883-aa19-7c2b85de87ab": {
		name: "Elevated Faith",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"11cc66c8-5ebb-4b23-a29b-0385302426c2": {
		name: "Stacked Skincare",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"a40b803a-1c6a-4b64-8d6f-d2ed306d4dc5": {
		name: "Beachwaver",
		inlineStyles: {
			zIndex: 1,
		},
	},
	"98fb1942-28a7-4e49-9e70-7be14c83d29a": {
		name: "Needed",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"38179977-a509-4b08-840e-d7e5621ae78a": {
		name: "Carbon38",
		inlineStyles: {
			zIndex: 1,
		},
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_LAST,
	},
	"5251c5f0-5cf5-4683-947d-5d68da1f05ac": {
		name: "Bandier",
		inlineStyles: {
			zIndex: 1,
		},
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_LAST,
	},
	"ebf56211-a2a5-4115-bbfb-985323b16d04": {
		name: "Bamboo Ave",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"745c7940-9775-487e-b7ad-19206a05a6c0": {
		name: "Bodily",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"7ac3aced-989e-4140-9708-62f37a0ce3d4": {
		name: "Jordan Craig",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"2ee48ff7-acd1-43a8-a1b7-cc2f52af32cd": {
		name: "Wild Cosmetics",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"87b82e62-dafc-435d-8378-d1306467d451": {
		name: "Fulton",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"03c00b34-737c-4bcb-939a-a287ee4436d7": {
		name: "Versed Skin",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"70671652-49d3-4f63-be0e-207eda56cd2a": {
		name: "Thursday Boot",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"7610c326-75dd-4e4e-91b9-2949f6f5b639": {
		name: "Portland Leather",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"8c64e304-d013-4178-b79c-c1cde67ca069": {
		name: "Mando",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_UNDER_MAP,
	},
	"4c61e280-b16f-4be0-87f4-3651b392f892": {
		name: "VKTRY",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"09c10b2d-9efe-45be-a355-27aea231f321": {
		name: "PJ Salvage",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"fb53b759-d1d9-4972-9657-185c5fcb2a48": {
		name: "Primary",
		getMountAfter: MOUNT_AFTER_MAP.THANK_YOU_ADDITIONAL_CONTENT,
	},
	"00d73cf3-a588-4df2-91c8-0c8fe64eb154": {
		name: "Blenders Eyewear",
		getMountAfter: MOUNT_AFTER_MAP.THANK_YOU_ADDITIONAL_CONTENT,
	},
	"3383886f-0410-489b-96f4-28ea6d839407": {
		name: "REEF",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_LAST,
	},
	"3c30cfa6-459b-4903-a9ea-e7a9bd9eec76": {
		name: "Cali's Books",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"0d344a1a-fa4c-4836-82d8-7da0bb04a60d": {
		name: "Bonafide Health",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"610a35a9-4902-4099-90cd-f38b3fb6d67f": {
		name: "Skinfix",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"bdcafd81-1a76-4952-bf98-0b341b79e621": {
		name: "Plant Therapy",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"7b172d6b-dc77-41c4-9107-e02ff3ae0cf6": {
		name: "Art of Tea",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"bc101246-d611-427a-8614-e3992545596f": {
		name: "Cymbiotika",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_LAST,
	},
	"77ea3316-fd43-4151-9387-817b5492c87f": {
		name: "Tumble - Washable Rugs",
		getMountAfter: () => document.querySelector(".step__sections"), // Kno is after last content box
	},
	"b832d838-7cbc-4975-8937-526d3e22039b": {
		name: "Pura Vida Bracelets",
		getMountAfter: MOUNT_AFTER_MAP.TARGETED_CUSTOM_ANCHOR(
			MOUNT_AFTER_MAP.THANK_YOU_ADDITIONAL_CONTENT
		),
	},
	"352a60c0-b5e5-439d-8527-afad51d80101": {
		name: "Rebel Stork",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},

	"afcd9016-5429-4f23-b587-bb6817a79acd": {
		name: "Aurate",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"fa8702e6-5f2b-4bf1-b66d-82cc3c493c3a": {
		name: "Laundry Sauce",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"b30a98ce-874b-4847-8e18-2c669cf9045d": {
		name: "Naked & Thriving Skincare",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"f0523dcb-1ce5-42cf-bd79-322aaaec8c76": {
		name: "Molekule",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_LAST,
		fullscreenStyles: {
			zIndex: 10,
		},
		inlineStyles: {
			zIndex: 1,
		},
	},
	"4fd82bf4-e652-4578-a82c-2fed63155dfd": {
		name: "Soylent",
		fullscreenStyles: {
			zIndex: 10,
		},
		inlineStyles: {
			zIndex: 1,
		},
	},
	"0f655c94-5aea-4d62-85c3-f43f6f26ba77": {
		name: "Crown Affair",
		fullscreenStyles: {
			zIndex: 10,
		},
		inlineStyles: {
			zIndex: 1,
		},
	},
	"cbd82839-a7cd-4105-9778-54b3669d0976": {
		name: "Caramels.com",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"73066fe8-1161-4201-bdfb-c2ce98e576ce": {
		name: "chocolate.com",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"a5fb910e-1a98-4e3f-8afa-4b764ce82c0d": {
		name: "Pretzels.com",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"f26f6861-96ad-41cb-9597-839078e23015": {
		name: "Licorice",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"c73e6384-9c37-4dc8-9646-3b195c00a141": {
		name: "Brighton",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"876c034e-8a31-4643-a245-83dd27f44713": {
		name: "Oleada",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"92230a6f-8aea-4f1c-a1f3-fe72dcba4ccd": {
		name: "Fuego",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"35056252-2369-41e6-88f3-0074b192e951": {
		name: "Hallelujah Diet",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"9e100dc3-9656-40d1-8bf0-e8d6cb48a988": {
		name: "R+Co",
		inlineStyles: {
			zIndex: 1,
		},
	},
	"e13ea4e9-a1f4-49c5-8069-1e6a35a3b1ba": {
		name: "ThirdLove",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"fc74c304-f698-435b-8d81-dbb9fcccd930": {
		name: "Vice Versa",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"ba18fc2e-e302-4af2-bf86-ec952e921220": {
		name: "Hug Sleep",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"23075f32-98ec-4f55-906d-41f36391fdb1": {
		name: "Ned",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_LAST,
	},
	"9cc406ce-6773-43c5-84f3-168ac17d77cb": {
		name: "Summer Fridays",
		inlineStyles: {
			zIndex: 1,
		},
	},
	"b5410dce-bc4c-454f-b648-ba018d83437f": {
		name: "Ketone-IQ",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_LAST,
	},
	"74c2bafb-634c-4928-ae6f-932e458363c6": {
		name: "Maison Miru",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},
	"42bc3637-e72b-456b-887d-ccde01d79ccc": {
		name: "Fair Harbor",
		getMountAfter: MOUNT_AFTER_MAP.MOUNT_BEFORE_MAP,
	},
	"7b66a89a-580d-411b-9689-01bfb657f8e3": {
		name: "OrnamentallyYou",
		getMountAfter: MOUNT_AFTER_MAP.CONTENT_BOX_0,
	},
};

const PROPERTY_GET_MAP = {
	inlineZIndex: "izi",
	fullscreenZIndex: "fzi",
	preventBodyScrollLock: "bsl",
	mountAfterSelector: "mas",
	mountAfterIndex: "mai",
};

const MOUNT_AFTER_IDS = {
	0: MOUNT_AFTER_MAP.DEFAULT, //"@default",
	1: MOUNT_AFTER_MAP.SECTION_1, //"@section-1",
	2: MOUNT_AFTER_MAP.THANK_YOU_ADDITIONAL_CONTENT, //"@thank-you-additional",
	3: MOUNT_AFTER_MAP.CONTENT_BOX_0, //"@content-box-0",
	4: MOUNT_AFTER_MAP.CONTENT_BOX_1, //"@content-box-1",
	5: MOUNT_AFTER_MAP.CONTENT_BOX_2, //"@content-box-2",
	6: MOUNT_AFTER_MAP.CONTENT_BOX_3, //"@content-box-3",
	7: MOUNT_AFTER_MAP.CONTENT_BOX_LAST, //"@content-box-last",
	8: MOUNT_AFTER_MAP.COOP_MOUNT_AFTER, //"@coop-mount-after",
	9: MOUNT_AFTER_MAP.THANK_YOU_CHECKOUT_TEXT, //"@thank-you-checkout",
	10: MOUNT_AFTER_MAP.WOOCOMMERCE_ORDER_OVERVIEW, //"@woocommerce-overview",
	11: MOUNT_AFTER_MAP.MAIN_CONTENT, //"@main-content",
};

console.log("window.discoCustomizations :>> ", window.discoCustomizations);

let currentPublisher = document.currentScript.getAttribute("publisher_id");
if (!currentPublisher) {
	currentPublisher = GETScript("publisher_id");
}

// DEBUG logs
console.log("currentPublisher :>> ", currentPublisher);
console.log(
	"QUERY_DICT :>> ",
	window.discoCustomizations?.[currentPublisher]?.["query_dict"]
);

const extractCustomization = (key) => {
	const customizationStore =
		window.discoCustomizations?.[currentPublisher]?.["query_dict"] || {};
	return customizationStore?.[key] || undefined;
};

if (
	window.discoCustomizations &&
	typeof window.discoCustomizations === "object" &&
	currentPublisher in window.discoCustomizations &&
	!SPECIAL_PUBLISHERS_PROPERTIES_BASE[currentPublisher]
) {
	SPECIAL_PUBLISHERS_PROPERTIES_BASE[currentPublisher] = {};
}

export const SPECIAL_PUBLISHERS_PROPERTIES = Object.entries(
	SPECIAL_PUBLISHERS_PROPERTIES_BASE
).reduce((acc, [key, prop]) => {
	const inlineZIndex = extractCustomization(PROPERTY_GET_MAP.inlineZIndex);
	const fullscreenZIndex = extractCustomization(
		PROPERTY_GET_MAP.inlineZIndex
	);
	const preventBodyScrollLock = extractCustomization(
		PROPERTY_GET_MAP.preventBodyScrollLock
	);

	const mountAfterSelector = extractCustomization(
		PROPERTY_GET_MAP.mountAfterSelector
	);

	console.log("mountAfterSelector :>> ", mountAfterSelector);

	const mountAfterIndex = extractCustomization(
		PROPERTY_GET_MAP.mountAfterIndex
	);

	return {
		...acc,
		[key]: {
			...prop,
			inlineStyles:
				inlineZIndex && !isNaN(inlineZIndex)
					? {
							...prop.inlineStyles,
							zIndex: Number(inlineZIndex),
					  }
					: prop.inlineStyles,
			fullscreenStyles:
				fullscreenZIndex && !isNaN(fullscreenZIndex)
					? {
							...prop.fullscreenStyles,
							zIndex: Number(fullscreenZIndex),
					  }
					: prop.fullscreenStyles,
			preventBodyScrollLock:
				preventBodyScrollLock == "0" || preventBodyScrollLock == "false"
					? false
					: preventBodyScrollLock
					? "true"
					: prop.preventBodyScrollLock,

			getMountAfter:
				mountAfterSelector !== undefined
					? (...args) => {
							console.log(
								"isNaN(mountAfterSelector) :>> ",
								isNaN(mountAfterSelector)
							);
							if (!isNaN(mountAfterSelector)) {
								console.log(
									"read MAS :>> ",
									MOUNT_AFTER_IDS[Number(mountAfterSelector)](
										...args
									)
								);
								return MOUNT_AFTER_IDS[
									Number(mountAfterSelector)
								](...args);
							}
							const els =
								document.querySelectorAll(mountAfterSelector);

							if (
								mountAfterIndex &&
								isNaN(mountAfterIndex) &&
								Number(mountAfterIndex) >= 0 &&
								Number(mountAfterIndex) < els.length
							)
								return els[Number(mountAfterIndex)];

							return els[0];
					  }
					: prop.getMountAfter,
		},
	};
}, {});
